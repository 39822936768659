<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_user_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_user_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_system_user_add']" type="primary" @click="onAddUser">新增用户</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="用户名" class="ui-form__item">
						<a-input v-model:value="formModal.nickname"></a-input>
					</a-form-item>
					<a-form-item label="角色" class="ui-form__item">
						<a-select v-model:value="formModal.roleId" style="width: 190px;">
							<a-select-option v-for="(item, index) in AvailableList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="单位" class="ui-form__item">
						<a-select showSearch optionFilterProp="label" v-model:value="formModal.unitId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id" :label="item.name">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="部门" class="ui-form__item">
						<a-select v-model:value="formModal.depId" style="width: 190px;">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="状态" class="ui-form__item">
						<a-select v-model:value="formModal.status" style="width: 190px;">
							<a-select-option :value="1">是</a-select-option>
							<a-select-option :value="0">否</a-select-option>
							<!-- <a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option> -->
						</a-select>
					</a-form-item>
					<a-form-item label="上次登录时间" class="ui-form__item">
						<a-range-picker show-time v-model:value="clearTime"/>
					</a-form-item>
					<div style="text-align: center;">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</div>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'up.nickname'">用户名</a-checkbox>
							<a-checkbox :value="'roleName'">角色</a-checkbox>
							<a-checkbox :value="'u.name'">单位</a-checkbox>
							<a-checkbox :value="'d.name'">部门</a-checkbox>
							<a-checkbox :value="'disabled'">状态</a-checkbox>
							<a-checkbox :value="'au.last_time'">上次登录时间</a-checkbox>
							<a-checkbox :value="'au.create_time'">创建时间</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.lastTime) }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.isDisabled === 1">禁用</div>
							<div v-else>正常</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_user_edit']" v-if="record.isEdit===1" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_user_disabled']" v-if="record.isEdit===1&&record.isDisabled === 1" type="link" size="small" @click="onChangeState(record,0)">启用</a-button>
							<span></span>
							<a-button v-permission="['pc_system_user_disabled']" v-if="record.isEdit===1&&record.isDisabled === 0" type="link" size="small" danger @click="onChangeState(record,1)">禁用</a-button>
							<span></span>
							<a-button v-permission="['pc_system_user_delete']" v-if="record.isEdit===1" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.lastTime) }}
						</template>
						<template v-if="column.key === 'status'">
							<div v-if="record.isDisabled === 1">禁用</div>
							<div v-else>正常</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_user_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_user_disabled']" v-if="record.isDisabled === 1" type="link" size="small" @click="onChangeState(record,0)">启用</a-button>
							<span></span>
							<a-button v-permission="['pc_system_user_disabled']" v-if="record.isDisabled === 0" type="link" size="small" danger @click="onChangeState(record,1)">禁用</a-button>
							<span></span>
							<a-button v-permission="['pc_system_user_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<saveModal ref="saveModalRef" @refresh="onRefresh" :AvailableList="AvailableList" :dimensionList="dimensionList"></saveModal>
	</div>
</template>

<script>
	import { userList, userDel, userChangeStatus, getMyAvailableList,getDataList} from '@/service/modules/system.js';
	import { Icon } from '@/components/icon/icon.js';
	import saveModal from './components/saveModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, saveModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {
					startTime: undefined,
					endTime: undefined,
					isOpen: 1,
					sort:[{
						sort: "desc",
						key: "au.update_time"
					}]
				},
				clearTime: [], // 存储时间 用来清空重置
				list: [],
				type: 'userMangerStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				AvailableList: [],
				sortList:[],
				getDataFun: userList,
				dimensionList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: "index",
					width: 80
				}, {
					title: '用户名',
					align: 'center',
					dataIndex: "nickname"
				}, {
					title: '账号',
					align: 'center',
					dataIndex: "username"
				}, {
					title: '手机号',
					align: 'center',
					dataIndex: "phone"
				}, {
					title: '角色',
					align: 'center',
					dataIndex: "roleName"
				}, {
					title: '单位',
					align: 'center',
					dataIndex: "unitName"
				}, {
					title: '部门',
					align: 'center',
					dataIndex: "departmentName"
				}, {
					title: '上次登录时间',
					align: 'center',
					key: 'time',
					// dataIndex: "lastTime"
				}, {
					title: '创建时间',
					align: 'center',
					key: 'createTime',
					dataIndex: "createTime"
				},{
					title: '状态',
					align: 'center',
					key: 'status',
					dataIndex: "isDisabled"
				}, {
					title:'操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 180
				}],
			}
		},
		created() {
			this.getMyAvailableList();
			this.onSearch();
			this.getDataSetting();
		},	
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await userList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			isOpen: 1,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("usertest",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			// 获取可以分配的维度
			async getDataSetting(){
				let ret = await getDataList({});
				if(ret.code == 200){
					this.dimensionList = ret.data;
				}
			},

			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.formModal.isOpen = 1
				this.clearTime = []
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));
				if (this.clearTime && this.clearTime.length) {
					this.searchData.startTime = this.clearTime[0].unix();
					this.searchData.endTime = this.clearTime[1].unix();
				}
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			async getMyAvailableList() {
				let ret = await getMyAvailableList({});
				if (ret.code === 200) {
					this.AvailableList = ret.data
				}
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onAddUser() {
				this.$refs.saveModalRef.open();
			},
			onEdit(row) {
				this.$refs.saveModalRef.open(row);
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: "确定删除吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await userDel({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								// this.getData();
								this.onSearch()
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onRefresh() {
				// this.getData()
				this.onSearch()
			},
			onChangeState(record,state) {
				this.$confirm({
					title: '提示',
					content: "确定修改状态吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await userChangeStatus({
								id: record.id,
								status: state
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('修改成功');
								// this.getData();
								this.onSearch()
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onChangeFun(date, dateString) { // 时间变化的回调
			  this.clearTime = date
			  let starDate = new Date(dateString[0])
			  let endDate = new Date(dateString[1])
			  // console.log(starDate.valueOf(), endDate.valueOf());
			  this.formModal.startTime = starDate.valueOf()/1000
			  this.formModal.endTime = endDate.valueOf()/1000
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>
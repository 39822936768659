<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit" >
			<template #title>
				<modalHeader :title="isEdit ? '编辑用户' : '新增用户'"></modalHeader>
			</template>
			<a-spin :spinning="loading">
			<div v-if="!isEdit">
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
					<!-- <a-form-item label="账户" name="username" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.username" placeholder="请输入姓名"></a-input>
					</a-form-item>
					 -->
					<a-form-item label="用户名" name="nickname" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-form-item-rest>
							<userSelect v-model:value="modelRef.id" @change="onAssetsChange" placeholder="请选择人员">
								<template #value>
									{{ userData.nickname }}
								</template>
							</userSelect>
						</a-form-item-rest>
					</a-form-item>
					<!-- :rules="[{ required: true, pattern: /^1[3-9]\d{9}$/, message: '必填项不允许为空' }]" -->
					<a-form-item label="手机号" name="phone">
						<a-input v-model:value="modelRef.phone" placeholder="请输入手机号" disabled></a-input>
					</a-form-item>
					
					<a-form-item label="密码" name="password" :rules="isEdit ? [{pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/, message: '请输入大小写字母+数字组合，且长度在8位至16位之间'}] : [{ required: true, message: '必填项不允许为空' }, {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/, message: '请输入大小写字母+数字组合，且长度在8位至16位之间'}]">
						<a-input type="password" v-model:value="modelRef.password" placeholder="请输入密码"></a-input>
					</a-form-item>
					
					<a-form-item label="请选择角色">
						<a-select mode="tags" v-model:value="modelRef.roleIds" placeholder="请选择角色" @change="onChnage">
							<a-select-option v-for="(item, index) in AvailableList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item :label="item.name" v-for="item in newDimensionList" :key="item.code" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-select allowClear :showSearch="item.code==='unit'" optionFilterProp="label" mode="multiple" v-model:value="modelRef[item.code]" :placeholder="`请选择${item.name}`"  @change="(e) => onSelectDataChange(e, item.code)">
							<a-select-option v-for="itemDinmension in dimensionObj[item.code]" :key="itemDinmension.id" :value="itemDinmension.id" :label="itemDinmension.name">{{itemDinmension.name}}</a-select-option>
						</a-select>
					</a-form-item>
					<!-- <a-form-item label="新密码" v-if="isEdit" name="newPassword" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input type="password" v-model:value="modelRef.newPassword" placeholder="请输入新密码"></a-input>
					</a-form-item> -->
					
					<!-- <a-form-item label="单位" name="unitId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select v-model:value="modelRef.unitId" placeholder="请选择所在单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>	
					</a-form-item>
					
					<a-form-item label="部门" v-if="modelRef.departmentId" name="departmentId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select v-model:value="modelRef.departmentId" placeholder="请选择所在部门">
							<a-select-option v-for="(item, index) in $store.state.count.departmentList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="角色" name="roleIds" :rules="[{ required: true, message: '必选项不允许为空' }]">
							<a-select mode="tags" v-model:value="modelRef.roleIds" placeholder="请选择用户角色">
								<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{item.roleName}}</a-select-option>
							</a-select>
					</a-form-item> -->
					
					<a-form-item label="状态">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-form>
			</div>
			<div v-else>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
					<a-form-item label="姓名" name="nickname" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.nickname" placeholder="请输入姓名"></a-input>
					</a-form-item>
					<!-- :rules="[{ required: true, pattern: /^1[3-9]\d{9}$/, message: '请填写正确手机号' }]" -->
					<a-form-item label="手机号" name="phone">
						<a-input v-model:value="modelRef.phone" placeholder="请输入手机号"></a-input>
					</a-form-item>
					
					<a-form-item label="密码" name="password" :rules="isEdit ? [{pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/, message: '请输入大小写字母+数字组合，且长度在8位至16位之间'}] : [{ required: true, message: '必填项不允许为空' }, {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/, message: '请输入大小写字母+数字组合，且长度在8位至16位之间'}]">
						<a-input type="password" v-model:value="modelRef.password" placeholder="请输入密码"></a-input>
					</a-form-item>
					
					<a-form-item label="请选择角色">
						<a-select mode="tags" v-model:value="modelRef.roleIds" placeholder="请选择角色">
							<a-select-option v-for="(item, index) in AvailableList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item :label="item.name" v-for="item in newDimensionList" :key="item.code" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-select allowClear :showSearch="item.code==='unit'" optionFilterProp="label" mode="multiple" v-model:value="modelRef[item.code]" :placeholder="`请选择${item.name}`" @change="(e) => onSelectDataChange(e, item.code)">
							<a-select-option v-for="itemDinmension in dimensionObj[item.code]" :key="itemDinmension.id" :value="itemDinmension.id" :label="itemDinmension.name">{{itemDinmension.name}}</a-select-option>
						</a-select>
					</a-form-item>
					<!-- <a-form-item label="新密码" v-if="isEdit" name="newPassword" :rules="(modelRef.password === null || modelRef.password.length === 0)? [] :[{ required: true, message: '必填项不允许为空' }]"> -->
					<!-- <a-form-item label="新密码" v-if="isEdit" name="newPassword" >
						<a-input type="password" v-model:value="modelRef.newPassword" placeholder="请输入新密码"></a-input>
					</a-form-item> -->
					
					<a-form-item label="状态">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-form>
			</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	// import { getAllUserList } from '@/service/modules/global.js';
	import { Icon } from '@/components/icon/icon.js';
	import eventBus from '@/utils/eventBus.js';
	import { userUpdate, userAdd, getMyAvailableList,getDataList,getDataDimensionList } from '@/service/modules/system.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import userSelect from '@/components/userSelect/index.vue';
	export default {
		components: { Icon, modalHeader, userSelect },
		data() {
			return {
				loading: false,
				visible: false,
				isEdit: false,
				userData: [],
				newList: [],
				selPhone: undefined,
				nickname: undefined,
				// AvailableList: [],// 可分配角色
				timer: null,
				// departmentName: ''
				modelRef: {
					id: [],
					password: null
				},
				dimensionObj:{}
			}
		},
		props:{
			AvailableList:{
				type:Array,
				default:[]
			},
			dimensionList:{
				type:Array,
				default:[]
			},
			// dimensionObj:{
			// 	type:Array,
			// 	default:{}
			// }

		},
		computed: {
			newDimensionList() {
				this.newList = this.dimensionList ? this.$deepClone(this.dimensionList) : []
				if(this.modelRef.roleIds&&this.modelRef.roleIds.includes(4271)&&(this.modelRef.roleIds.includes(4270)||this.modelRef.roleIds.includes(4269))) {
					this.dimensionList.forEach((item,index)=>{
					  if(item.code === 'dept') {
							 this.newList.splice(index,1)
					  }
				    })
					return this.newList
				}else if(this.modelRef.roleIds&&this.modelRef.roleIds.length>0&&!this.modelRef.roleIds.includes(4271)){
					if(this.modelRef.roleIds.includes(4270)||this.modelRef.roleIds.includes(4269)) {
						this.newList.splice(2,1)
					}else if((this.modelRef.roleIds.includes(4274)||this.modelRef.roleIds.includes(4273)||this.modelRef.roleIds.includes(4272))) {
						this.newList.splice(1,2)
					}
					return this.newList
				}else {
					return this.dimensionList
				}
			}
		},
		// watch: {
		// 	modelRef: {
		// 		handler(newval,oldval) {
		// 			console.log("password",newval.password)
		// 		},
		// 		deep: true,
		// 		immediate:true
		// 	}
		// },
		methods: {
			// async getData(item,user) {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getAllUserList({
			// 			username: item,
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			this.userData= ret.data;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			
			getAllDataDimensionList(){
				this.dimensionList.forEach(async (item)=>{
					let ret = await getDataDimensionList({type:item.code})
					if(ret.code == 200){
						this.dimensionObj[item.code] = ret.data;
						if(ret.data.length > 0){
							this.dimensionObj[item.code].unshift({id:0,name:'全部'});
						}
					}
				})
			},

			async getMyAvailableList() {
				let ret = await getMyAvailableList({});
				if (ret.code === 200) {
					this.AvailableList = ret.data
				}
			},
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = this.$deepClone(this.modelRef);
					// if(this.isEdit) postData.oldPassword = postData.password
					postData.isOpen = 1 // 默认开通账号
					if(postData.roleIds) postData.roleIds = postData.roleIds.join(',')
					if(postData.unit) postData.unit = postData.unit.join(',')
					if(postData.backlogType) postData.backlogType = postData.backlogType.join(',')
					if(postData.dept) postData.dept = postData.dept.join(',')
					postData.id = postData.id[0];
					this.loading = true;
					try {
						let ret = await userUpdate(postData);
						// if (this.isEdit) {
						// 	ret = await userUpdate(postData);
						// } else {
						// 	ret = await userAdd(postData);
						// }
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							eventBus.$emit('refreshUser');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			// onChange(e) {
			// 	console.log(e)
			// },
			open(e) {
				this.getAllDataDimensionList();
				if (e && e.id) {
					this.modelRef = {
						id: [e.id],
						unitId: e.unitId,
						nickname: e.nickname,
						username: e.username,
						password: e.password,
						phone: e.phone,
						roleName: e.roleName,
						departmentName: e.departmentName,
						isDisabled: e.isDisabled,
						roleIds: e.roleIds,
						unit:e.unit,
						backlogType:e.backlogType,
						dept:e.dept
					}
					this.isEdit = true;
				} else {
					this.modelRef = {};
					this.modelRef.isDisabled = 0
					this.isEdit = false;
				}
				// this.getMyAvailableList()
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.modelRef = {};
				this.visible = false;
			},
			onSelectDataChange(e, code) {
				if (e.includes(0)) {
					this.modelRef[code] = [0];
					if (code == "unit") {
						this.getDeptDimensionList(0);
					}
				} else if (code == "unit") {
					this.modelRef["dept"] = [];
					this.getDeptDimensionList(this.modelRef['unit']);
				}
			},
			async getDeptDimensionList(unitIds) {
				let ret = await getDataDimensionList({
					type: "dept",
					unitIds: unitIds ? unitIds.join(',') : undefined
				});
				if (ret.code === 200) {
					this.dimensionObj["dept"] = ret.data;
					if(ret.data.length > 0){
						this.dimensionObj["dept"].unshift({id:0,name:'全部'});
					}
				}
			},
			onAssetsChange(userData) {
				if(userData.length !== 0) {
					this.userData= userData[0];
					this.modelRef.phone = userData[0].phone;
					this.modelRef.nickname = userData[0].nickname;
					this.modelRef.id = [userData[0].id]
					this.$refs.formRef.clearValidate();
				}else{
					this.userData = {}
					this.modelRef = {}
				}
				// this.getData(assetsData[0].code);
			},
			onChnage(val) {
				console.log(val,'kk');
			}
			// handleSearch(value) {
			// 	if(this.timer) {
			// 		clearTimeout(this.timer)
			// 	}
			// 	this.timer = setTimeout(() =>{
			// 		console.log("userkw", value)
			// 		this.timer = null
			// 		this.getData(value)
			// 	}, 500);
			// },
			// nickNameChange(value,option) {
			// 	console.log("选中",option)
			// 	this.modelRef.phone = option.phone
			// 	console.log("选中phone",this.modelRef.phone)
			// },
			// phoneChange(value,option) {
			// 	console.log("选中",option)
			// 	this.modelRef.nickname = option.nickname
			// 	console.log("选中nickName",this.modelRef.nickname)
			// }
		}
	}
</script>

<style>
</style>